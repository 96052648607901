@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


*,
*:before,
*:after {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
}

.no-style{
	text-decoration: none;
}

h2,
h5 {
	margin-block-end: 0;
	margin-block-start: 0;
}

address {
	margin-bottom: 20px;
	font-style: normal;
	line-height: 1.42857143;
}
