@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
*,
*:before,
*:after {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
}

.no-style{
	text-decoration: none;
}

h2,
h5 {
	-webkit-margin-after: 0;
	        margin-block-end: 0;
	-webkit-margin-before: 0;
	        margin-block-start: 0;
}

address {
	margin-bottom: 20px;
	font-style: normal;
	line-height: 1.42857143;
}

.container {
	width: 100%;
	height: 100%;
	position: relative;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.mill {
	background-color: #ac1f25;
	width: 10px;
	height: 60%;
	position: absolute;
	-webkit-transform: perspective(12px) rotateX(3deg);
	        transform: perspective(12px) rotateX(3deg);
	z-index: 1;
}

.fan {
	background-color: #ac1f25;
	width: 15px;
	height: 15px;
	border-radius: 100%;
	margin-bottom: 50%;
	z-index: 2;

	-webkit-animation-name: spin;

	        animation-name: spin;
	-webkit-animation-duration: 3000ms;
	        animation-duration: 3000ms;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
}

.fan_blade_outer_1 {
	-webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
}

.fan_blade_outer_2 {
	-webkit-transform: rotate(120deg);
	        transform: rotate(120deg);
	position: absolute;
	left: 17px;
	top: 3px;
}

.fan_blade_outer_3 {
	-webkit-transform: rotate(240deg);
	        transform: rotate(240deg);
	position: absolute;
	left: 4px;
	top: 17px;
}

.fan_blade_down {
	width: 0;
	height: 0;
	top: -17px;
	left: 4px;
	position: absolute;
	border-style: solid;
	border-width: 20px 3.5px 0 3.5px;
	border-color: #ac1f25 transparent transparent transparent;
	z-index: 1;
}

.fan_blade_up {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 3.5px 100px 3.5px;
	border-color: transparent transparent #ac1f25 transparent;

	position: absolute;
	top: -117px;
	left: 4px;
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

