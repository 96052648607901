.container {
	width: 100%;
	height: 100%;
	position: relative;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.mill {
	background-color: #ac1f25;
	width: 10px;
	height: 60%;
	position: absolute;
	transform: perspective(12px) rotateX(3deg);
	z-index: 1;
}

.fan {
	background-color: #ac1f25;
	width: 15px;
	height: 15px;
	border-radius: 100%;
	margin-bottom: 50%;
	z-index: 2;

	animation-name: spin;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.fan_blade_outer_1 {
	transform: rotate(0deg);
}

.fan_blade_outer_2 {
	transform: rotate(120deg);
	position: absolute;
	left: 17px;
	top: 3px;
}

.fan_blade_outer_3 {
	transform: rotate(240deg);
	position: absolute;
	left: 4px;
	top: 17px;
}

.fan_blade_down {
	width: 0;
	height: 0;
	top: -17px;
	left: 4px;
	position: absolute;
	border-style: solid;
	border-width: 20px 3.5px 0 3.5px;
	border-color: #ac1f25 transparent transparent transparent;
	z-index: 1;
}

.fan_blade_up {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 3.5px 100px 3.5px;
	border-color: transparent transparent #ac1f25 transparent;

	position: absolute;
	top: -117px;
	left: 4px;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
